<template>
  <div class="home">
    <div>
      <img src="../../../public/images/xqy/防水门铃DW5/images/DW5_01.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/防水门铃DW5/images/DW5_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/防水门铃DW5/images/DW5_03.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/防水门铃DW5/images/DW5_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/防水门铃DW5/images/DW5_05.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/防水门铃DW5/images/DW5_06.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/防水门铃DW5/images/DW5_07.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/防水门铃DW5/images/DW5_08.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/防水门铃DW5/images/DW5_09.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/防水门铃DW5/images/DW5_10.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "防水门铃-DW5",
  components: {
    elMenu, foot, navigation
  },

}
</script>

<style scoped>

</style>